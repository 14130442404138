import {storeModel} from "../Models/store";
import {Envia} from "../Models/Bill";
import {BillProducts, product} from "../Models/Products";
import {StoreClient} from "../Models/StoreClient";
import {add} from "lodash";
import {UserData} from "../Models/User.Model";

export function productSelectorByCurrency(currency: string): (p: product) => boolean {
    return currency === 'COP'
        ? (p: product) => p.isActive
        : (p: product) => p.isActiveEcuador;
}

export function priceSelectorByCurrency(currency: string): (p: product) => number {
    return currency === 'COP'
        ? (product: product) => product.selling_price
        : (product: product) => product.usD_Selling_price;
}

export function prepareSender(store: storeModel | undefined,
                              productData: { billProducts: BillProducts[], products: product[] } | string,
                              isCashOnDelivery: boolean,
                              client: StoreClient,
                              deliveryComments: string
): Envia {

    return {
        cod_FormaPago: 4,
        mPesoVolumen_K: 1,
        cod_Cuenta: isCashOnDelivery ? 518 : 517,
        nom_remitente: store !== undefined ? `CREC - ${store.name}` : "-",
        dir_remitente: "Carrera 50 FF # 10 sur 150",
        tel_remitente: store !== undefined ? store.cel : "-",
        ced_remitente: store !== undefined ? store.identification : "-",
        texto_Guia: `Barrio:${client.neighborhood} ${formatBillProducts(productData)} / ${deliveryComments}`,
    };
}
export function emptyClient(): StoreClient{
    return {
        adress_field_1: "",
        adress_field_2: "",
        adress_field_3: "",
        adress_field_4: "",
        adress_field_5: "",
        adress_field_6: "",
        adress_field_7: "",
        adress_field_8: "",
        adress_field_9: "",
        adress_field_10: "",
        adress_field_11: "",
        adress_field_12: "",
        adress_indications: "",
        building: "",
        carrera: "",
        cellphone: "",
        cityId: undefined,
        created_at: new Date(),
        created_by: 0,
        email: "",
        gender: "",
        id: undefined,
        identification: "",
        name: "",
        neighborhood: "",
        phone: "",
        updated_at: new Date(),
        warningComments: "",
    };
}
export function prepareClient(userData: UserData,
                              fromClient: StoreClient,
                              cellphone: string,
                              fullname: string,
                              idNumber: string,
                              email: string,
                              cityId: number
): StoreClient {
    return {
        ...fromClient,
        cellphone: cellphone,
        name: fullname,
        identification: idNumber,
        email: email,
        cityId: cityId,
        created_at: fromClient ?? new Date(),
        created_by: userData.userID
    } as unknown as StoreClient;
}

export function calculateCollectAmount(billProducts: BillProducts[], products: product[], currency: string = 'COP'){

    const suggestedPriceSelector = currency === 'COP'
        ? (product: product) => product.suggested_selling_price
        : (product: product) => product.usD_Suggested_selling_price;

    const price = billProducts.reduce((sum: number, billProduct: BillProducts) => {
        let product = products.find((prod: any) => prod.id === billProduct.productId) as product;
        return sum + suggestedPriceSelector(product) * billProduct.quantity
    }, 0);

    return parseFloat(price.toFixed(2));
}

export function formatBillProducts(productData: { billProducts: BillProducts[], products: product[] } | string): string {
    if (typeof productData === 'string')
        return productData;

    const {billProducts, products} = productData;

    return billProducts
        .map(bp => {
            const product = products.find(p => p.id === bp.productId);
            return `${bp.quantity}x ${product?.name}`;
        })
        .join();
}